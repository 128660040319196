<template>
  <div class="page">
    <div class="title">添加公告</div>

    <el-divider></el-divider>

    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="state">
        <!-- <el-input
          v-model="form.job_type"
          placeholder="例：全职/兼职/小时工"
        ></el-input> -->
        <el-select v-model="form.state" placeholder="请选择">
          <el-option
            v-for="item in state_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <div id="editor"></div>
      </el-form-item>

      <el-form-item class="form_btns">
        <div class="form_btns">
          <div class="custom_button plain no_select" @click="onSubmit">
            保存
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import E from "wangeditor";

export default {
  data() {
    return {
      form: {
        title: "",
        state: 1,
        content: "",
      },
      state_options: [
        {
          value: 1,
          label: "显示",
        },
        {
          value: 2,
          label: "隐藏",
        },
      ],
      editor: null,
      rules: {
        title: [{ required: true, message: "请填写标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      noticeId: "",
    };
  },
  // https://www.kancloud.cn/wangfupeng/wangeditor3/335774
  //使用的是这个npm
  created() {
    let params = this.$route.params;

    if (Object.keys(params).length > 0) {
      localStorage.setItem("noticeText", JSON.stringify(params));
    } else {
      params = JSON.parse(localStorage.getItem("noticeText")) ?? {};
    }
    console.log(params, this.$route);

    if (Object.keys(params).length > 0) {
      this.form = {
        title: params.title,
        state: params.state,
        content: params.content,
      };
      this.noticeId = params.id;
    }
  },
  mounted() {
    this.editor = new E("#editor");

    // 上传图片
    this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      console.log(resultFiles);
      resultFiles.forEach((item) => {
        this.uploadFile(item)
          .then((res) => {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.data.data.full_link);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    };

    this.editor.create();
    this.editor.txt.html(this.form.content);
    // this.editor.txt.setHtml("<p>hello <strong>world</strong></p>");
  },

  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("enterprise", ["postEnterpriseNotice"]),

    getHtmlFromEditor() {
      return this.editor.txt.html();
    },

    onSubmit() {
      this.form.content = this.getHtmlFromEditor();

      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          let loading = this.$loading();
          let params = this.form;
          if (this.noticeId) {
            params.id = this.noticeId;
          }
          // 添加
          this.postEnterpriseNotice(params)
            .then((res) => {
              console.log(res);
              this.$message.success(res.data.msg);
              setTimeout(() => {
                loading.close();
                this.$router.back();
              }, 2000);
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("noticeText");
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #222222;
}

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.el-textarea {
  width: 400px;
  font-size: 14px;
  color: #222222;
}

.form_btns {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
